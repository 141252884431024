<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>My Earnings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit" class="d-none d-sm-flex">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 110px" v-bind="attrs" v-on="on">
            <span>
              <FiscalYearField
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                @inputObj="
                  selectedFiscalYear = $event;
                  getData();
                "
                :client="selectedClient"
                flat
                solo-inverted
                hide-details
                class="mr-2"
                use-store
              ></FiscalYearField>
            </span></div></template
        >Fiscal Year</v-tooltip
      >
      <v-btn @click="getData" color="primary" class="d-none d-sm-flex">Search</v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            fab
            small
            @click="$router.push({ name: 'allClaimTypesReporting' })"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <span>Generate Report</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <ParticipantEarnedWidget :keyword="search"></ParticipantEarnedWidget>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <ParticipantPendingPaymentWidget :keyword="search"></ParticipantPendingPaymentWidget>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="items"
              :headers="headers"
              :server-items-length="total"
              :options.sync="options"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
              show-expand
              @item-expanded="loadClaimDetails"
              :expanded.sync="expanded"
              :single-expand="true"
            >
              <template v-slot:item.createdDate="{ item }">
                {{ item.updatedDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
              </template>
              <template v-slot:item.awardVehicleInfo="{ item }">
                <span v-if="item.referenceBankTransaction">
                  <span v-if="item.referenceBankTransaction.achNumber">
                    ACH Ref #{{ item.referenceBankTransaction.achNumber }}
                    <span v-if="item.referenceBankTransaction.achDate">
                      ({{
                        item.referenceBankTransaction.achDate | formatDateClient("MM/DD/YYYY", selectedClient)
                      }})</span
                    >
                  </span>
                  <span v-else-if="item.referenceBankTransaction.creditMemoNumber">
                    Credit Memo #{{ item.referenceBankTransaction.creditMemoNumber }}
                    <span v-if="item.referenceBankTransaction.creditMemoDate"
                      >({{
                        item.referenceBankTransaction.creditMemoDate | formatDateClient("MM/DD/YYYY", selectedClient)
                      }})</span
                    >
                  </span>
                  <span v-else-if="item.referenceBankTransaction.checkNumber">
                    Check Number #{{ item.referenceBankTransaction.checkNumber }}
                    <span v-if="item.referenceBankTransaction.checkDate"
                      >({{
                        item.referenceBankTransaction.checkDate | formatDateClient("MM/DD/YYYY", selectedClient)
                      }})</span
                    >
                  </span>
                </span>
              </template>
              <template v-slot:item.amount="{ item }">
                {{ Math.abs(item.calculatedAmount) | toNumber(2, selectedProgram) }}
                {{ item.calculatedAmountCurrency ? item.calculatedAmountCurrency : "" }}
              </template>
              <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length">
                  <ClaimDetailTable :claim="claim" v-if="claim"></ClaimDetailTable>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import ClaimDetailTable from "../../gapp-components/components/tables/ClaimDetailTable.vue";
import ParticipantPendingPaymentWidget from "./widget/ParticipantPendingPaymentWidget.vue";
import ParticipantEarnedWidget from "./widget/ParticipantEarnedWidget.vue";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import { mapGetters } from "vuex";

export default {
  name: "MyEarnings",
  metaInfo: {
    title: "My Earnings"
  },
  components: { FiscalYearField, ParticipantEarnedWidget, ParticipantPendingPaymentWidget, ClaimDetailTable },
  data() {
    return {
      search: "",
      loading: true,
      loadingClaim: false,
      claim: null,
      items: [],
      nameFilter: "",
      expanded: [],
      headers: [
        {
          text: "Detail",
          value: "data-table-expand",
          show: true
        },
        {
          value: "createdDate",
          text: "Earned Date",
          sortable: true
        },
        {
          value: "message",
          text: "Message",
          sortable: true
        },
        {
          value: "awardVehicle.name",
          text: "Award Type",
          sortable: true
        },
        {
          value: "awardVehicleInfo",
          text: "Award Info",
          sortable: true
        },
        {
          value: "amount",
          text: "Value",
          sortable: true,
          align: "right"
        }
      ],
      total: 0,
      options: {
        sortBy: ["createdDate"],
        sortDesc: [true],
        itemsPerPage: 10
      },
      selectedFiscalYear: {}
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {
        bank: { id: this.selectedParticipant.bank.id },
        voided: false,
        pending: false,
        hasReferenceTransaction: true
      };

      if (this.selectedFiscalYear) {
        filters.fiscalYear = this.selectedFiscalYear.fiscalYear;
      }

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      this.$api
        .post(
          "/api/bankTransactions/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadClaimDetails({ item }) {
      this.claim = null;
      let bankTransactionId = item.id;
      this.loadingClaim = true;
      this.$api.get("/api/claims/byBankTransaction/" + bankTransactionId).then(({ data }) => {
        this.claim = data;
        this.loadingClaim = false;
      });
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Reports", exact: true },
      { text: "My Earnings" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"])
  }
};
</script>
